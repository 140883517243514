.co {
  width: 50px;
}

.prodsearch-btn {
  background-color: coral;
  color: #fff;
}

.dashboard {
  letter-spacing: 0;
  font-size: 30px !important;
  font-weight: 800;
}

.dashboard-box {
  letter-spacing: 0;
  font-size: 20px !important;
  font-weight: 800;
}

.textspacing {
  letter-spacing: 0;
}

.textdecoration {
  text-decoration: none;
}

.back {
  background-color: #f2f2f2;
  height: 100vh;
}

.trans-head {
  background-color: #d0d0d0;
  font-size: 16px;
}

.search {
  display: flex;
  justify-content: flex-end;
}

.bot {
  margin-bottom: 45px !important;
}

.nav-sidebar .menu-is-opening>.nav-link i.right,
.nav-sidebar .menu-open>.nav-link i.right {
  -webkit-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}

.main-color {
  color: #81CF0E !important;
}

.commision-cards .commision-card-icon {
  opacity: .5;
}

.currency-icon {
  position: absolute;
  top: 0px;
  right: 5px;
}
